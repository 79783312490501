









import Vue, {PropOptions} from 'vue';
import LabSim from '@/tasks/components/simulations/LabSim.vue';

export default Vue.extend({
  name: 'LabFreezingPointDepression',
  components: {LabSim},
  inheritAttrs: false,
  props: {
    molecularMass: {
      type: Number,
      default: 128,
    } as PropOptions<number>,
  },
  computed: {
    labData(): string {
      return JSON.stringify({
        molecularMass: this.molecularMass,
      });
    },
  },
});
