<template>
  <lab-task><lab-freezing-point-depression :molecular-mass="molecularMass" /></lab-task>
</template>

<script>
import Vue from 'vue';
import LabTask from './labs/LabTask';
import LabFreezingPointDepression from '@/tasks/components/simulations/LabFreezingPointDepression';
import DynamicQuestionMixin from '@/tasks/mixins/dynamic-question';

export default Vue.extend({
  name: 'FreezingPointDepression',
  components: {
    LabFreezingPointDepression,
    LabTask,
  },
  mixins: [DynamicQuestionMixin()],
  computed: {
    unknownNumber() {
      return this.taskState.getValueBySymbol('unknownNumber').content.value;
    },
    molecularMass() {
      if (this.unknownNumber === 1) {
        return 178.23;
      } else if (this.unknownNumber === 2) {
        return 128.174;
      } else if (this.unknownNumber === 3) {
        return 147.0;
      } else if (this.unknownNumber === 4) {
        return 166.223;
      } else {
        return 1.0;
      }
    },
  },
});
</script>
